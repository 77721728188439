.container {
  width: 100%;
}

.innerContainer {
  margin: 0 72px;
  display: flex;
  flex-direction: row;
}

.tabList {
  padding-top: 10px;
  align-items: center;
  border-top: 1px solid #6c757d;
  border-bottom: 1px solid #6c757d;
}

.tabItem {
  font-family: 'GT America Medium', sans-serif;
  font-size: 16pt;
  cursor: pointer;
  padding: 16px;
}

.tabItem:not(.selected) {
  color: #6c757d;
}

.tabItem.selected {
  color: #744bff;
  border-bottom: 3px solid #744bff;
}

.tabItem:hover {
  color: #744bff;
}

.contentContainer {
  margin: 41px 72px;
}

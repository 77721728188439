.homeWrapper {
  display: flex;
  height: 100vh;
}

.leftSide {
  flex: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.rightSide {
  flex: 50%;
  position: relative;
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1; /* Ensure the image is displayed below other elements */
}

.logo {
  position: absolute;
  top: 2rem;
  left: 3rem;
  height: 2rem;
  width: auto;
}

.title {
  font-family: "GT-America-Standard", sans-serif;
  font-weight: 500;
  font-size: 48px;
  color: #495057;
}

.subtitle {
  font-family: "GT-America-Standard", sans-serif;
  font-weight: 400;
  font-size: 20pt;
  color: #6C757D;
  margin-top: 20px;
  margin-bottom: 30px;
}

.buttonContainer {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.button {
  display: block;
  width: 50%;
  min-width: 300px;
  margin: 1rem 0;
}

.buttonSpacer {
  height: 12px;
}

.glassMorphismSquare {
  position: absolute;
  width: 300px;
  height: 300px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
}

.loginContainer {
  position: absolute;
  top: 2rem;
  right: 3rem;
}

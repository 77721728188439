.table {
  width: 500px;
  border-collapse: separate;
  border-spacing: 20px;
  margin-bottom: 20px;
}

.field-name {
  text-align: right;
  width: 100px;
  font-weight: bold;
}

.input-box {
  font-size: 16px;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  margin-left: 10px;
  padding: 15px 10px 15px 10px;
  background-color: white;
  box-shadow: inset 0px 0px 0px 1px rgb(96, 40, 255);
  box-sizing: border-box;
}

.row {
  margin-bottom: 10px;
}

.boxcontainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: top;
  align-content: center;
}

.leftbox {
  width: calc(30% - 30px);
}

.rightbox {
  width: calc(70% - 30px);
}
.button {
    font-family: "GT-America-Standard", sans-serif;
    font-weight: 500;
    font-size: 15px;
    text-align: center;
    line-height: 42px;
    border-radius: 0;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
    outline: none;
  }

  .button.default {
    background-color: rgb(96, 40, 255);
    color: #ffffff;
  }

  .button.alternative {
    background-color: #ffffff;
    color: rgb(96, 40, 255);
    border: 2px solid rgb(96, 40, 255);
    box-sizing: border-box;

    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  .button.green {
    background-color: #009a4d;
    color: #ffffff;
    border: 2px solid #009a4d;
    box-sizing: border-box;

    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  .button.red {
    background-color: #9a0000;
    color: #ffffff;
    border: 2px solid #9a0000;
    box-sizing: border-box;

    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  .button.disabled {
    background-color: #dfdfdf;
    color: #7f7f7f;
    border: 2px solid #7f7f7f;
    box-sizing: border-box;

    display: inline-flex;
    justify-content: center;
    align-items: center;

  }

  .button:focus {
    box-shadow: 0 0 0 2px rgba(96, 40, 255, 0.5);
  }

  .button.green:focus {
    box-shadow: 0 0 0 2px #009a4d;
  }

  .button.red:focus {
    box-shadow: 0 0 0 2px #9a0000;
  }
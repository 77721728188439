/* General Table Styles */
.table {
    font-family: 'GT America Regular', sans-serif;
    border-collapse: collapse;
    width: 100%;
    border: 1px solid #E5EAEE;
    margin-top: 24px;
    text-align: center;
  }

  /* Header Styles */
  .table thead {
    background-color: #ffffff;
  }

  .table thead th {
    font-family: 'GT America Medium', sans-serif;
    font-size: 14pt;
    color: #6C757D;
    height: 72px;
    padding-left: 20px;
    padding-right: 20px
  }

  /* Body Rows Styles */
  .table tbody tr {
    height: 64px;
  }

  .table tbody tr:nth-child(even) {
    background-color: #ffffff;
  }

  .table tbody tr:nth-child(odd) {
    background-color: rgb(241, 245, 249);
  }

  .table tbody td {
    font-size: 13pt;
    color: #6C757D;
  }

  /* Pagination Styles */
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
  }

  .pagination li {
    margin: 0 5px;
  }

  .pagination a {
    display: block;
    width: 30px;
    height: 30px;
    background-color: rgba(116, 75, 255, 0.15);
    color: #744BFF;
    text-align: center;
    line-height: 30px;
    text-decoration: none;
  }

  .pagination a.selected {
    background-color: #744BFF;
  }

.colortag {
  padding: 6px 16px;
  border-radius: 100px;
  margin-left: auto;
  margin-right: auto;
  background: rgba(116, 75, 255, 0.1);
}

.colortag_container {
  display: flex;
  justify-content: center;
}


.button-container {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }

.td-left {
  text-align: right;
  font-weight: 700;
  padding-right: 10px;
  height: 40px;
  width: 150px;
}

.td-right {
  text-align: left;
  font-weight: 400;
}

textarea {
  width: 400px;
  height: 280px;
}

.textarea-mini {
  width: 200px;
  height: 100px;
}
.table {
  width: 500px;
  border-collapse: separate;
  border-spacing: 20px;
  margin-bottom: 20px;
}

.field-name {
  text-align: right;
  width: 100px;
  font-weight: bold;
}

.input-box {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding: 0.5em;
  background-color: white;
  box-shadow: inset 0px 0px 0px 1px rgb(96, 40, 255);
}

.row {
  margin-bottom: 10px;
}

/* GlassModal.module.css */
.modalBackground {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .glassModal {
    background: rgba(255, 255, 255, 0.7);
    padding: 40px;
    width: 500px;
    max-width: 90%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .closeButton {
    font-size: 24px;
    align-self: flex-end;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
.Input {
  width: 100%;
  border: 2px solid #6c757d;
  font-family: "GT-America-Standard", sans-serif;
  font-weight: 400;
  font-size: 14pt;
  color: #6c757d;
  outline: none;
  padding: 8px;
  margin-bottom: 12px;
  box-sizing: border-box;
}

.Input:focus {
  color: #6c757d;
  font-family: "GT-America-Standard", sans-serif;
  font-weight: 500;
  background-color: #E5EAEE;
  border-color: #6c757d;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  margin-left: 72px;
  margin-right: 72px;
}

.leftSide {
  display: flex;
  align-items: center;
}

.logo {
  height: 30px;
}

.separator {
  width: 1px;
  height: 40px;
  background-color: #6c757d;
  margin-left: 30px;
  margin-right: 30px;
}

.pageName {
  font-family: 'GT America Medium', sans-serif;
  font-size: 20px;
  color: #495057;
}

.rightSide {
  position: relative;
}

.iconContainer {
  position: relative;
}

.icon {
  cursor: pointer;
}

.dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #ffffff;
  border: 1px solid #e5eaee;
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 100; /* Add this line */
}

.dropdownItem {
  width: 100%;
  text-align: left;
  display: block;
  padding: 8px 16px;
  background-color: transparent;
  border: none;
  font-family: 'GT America Medium', sans-serif;
  font-size: 16px;
  color: #495057;
  cursor: pointer;
}

.dropdownItem:hover {
  background-color: #e5eaee;
}